<template>
  <div
    :class="`data-table report-table ${showCurrentSavedFilters ? 'summaries-expanded px-3' : 'px-0'}`"
    @mousemove="keepDragging($event)"
    @mouseup="stopDragging($event)"
  >
    <div
      v-if="isReady"
      class="table-groups-container"
    >
      <div
        v-if="isReadyAndNoData || allGroupsAreEmpty"
        class="pa-4 white mx-3"
      >
        No Results
      </div>

      <div
        v-for="group of tableGroups"
        v-else
        :key="group.groupName"
        :class="`table-group rounded-borders${ addPaddingToTable ? ' mt-3' : ''}`"
      >
        <div
          v-if="!group.isPartOfGroup || group.items.length > 0"
          :class="`${group.isPartOfGroup ? 'rounded-borders cursor-pointer px-6' : 'icewhite pb-2'}`"
        >
          <v-row
            class="row--dense overline"
            @click="toggleRows(group.groupName)"
          >
            <v-col
              v-if="group.groupName"
              class="d-inline-flex flex-grow col-10 align-center py-4"
            >
              <v-row>
                <span class="font-weight-bold primary--text">
                  {{ group.labels[0] }}
                </span>

                <span
                  class="font-weight-regular pl-3"
                >
                  {{ group.labels.slice(1).join(' :: ') }}
                </span>
              </v-row>
            </v-col>

            <v-spacer />

            <v-col
              v-if="group.groupName && !isProgrammaticRoute"
              :class="`d-inline-flex align-center justify-end coolgray--text text-right caption col-1 ${group.isPartOfGroup ? '' : 'pt-0'}`"
            >
              <div class="font-weight-medium">
                {{ formatNumber(group.items.length) }}
              </div>

              <div class="d-inline-flex d-sm-none d-md-inline d-lg-inline d-xl-inline align-center font-weight-light pl-1">
                {{ group.items.length === 1 ? 'Result' : 'Results' }}
              </div>
            </v-col>

            <v-col
              v-if="isProgrammaticRoute"
              :class="`d-inline-flex align-center justify-end coolgray--text text-right caption ${group.isPartOfGroup ? '' : 'pt-0'}`"
            >
              <v-row
                :class="`coolgray--text justify-start py-0`"
                style="min-width: fit-content"
                title="Results"
              >
                <span class="font-weight-bold">
                  {{ formatNumber(group.items.length) }}
                </span>

                <span class="d-inline-flex d-sm-none d-md-inline d-lg-inline d-xl-inline align-center font-weight-regular pl-1">
                  {{ group.items.length === 1 ? 'Result' : 'Results' }}
                </span>
              </v-row>

              <v-row
                v-for="(summary, summaryIndex) in group.summaries"
                :key="summaryIndex"
                :class="`coolgray--text justify-start py-0 my-0`"
                :title="summary.title"
                :style="`min-width: ${summary.widthPx};`"
              >
                <span class="font-weight-bold">
                  {{ summary.value }}
                </span>

                <span class="d-inline-flex d-sm-none d-md-inline d-lg-inline d-xl-inline align-center font-weight-regular pl-1">
                  {{ summary.title }}
                </span>
              </v-row>
            </v-col>
          </v-row>

          <v-data-table
            v-if="!group.isPartOfGroup || showRows.indexOf(group.groupName) !== -1"
            cell-class="font-weight-regular"
            dense
            :custom-sort="setTableSorting"
            :footer-props="tableFooterProps"
            :headers="visibleColumns"
            hide-default-header
            hide-default-footer
            :items="group.items || []"
            :items-per-page="syncItemsPerPage"
            :loading="group.items.length > 0 && (applyingFilter || resettingFilter)"
            multi-sort
            :page.sync="syncPage"
            :sort-by.sync="sortedByKeys"
            :sort-desc.sync="sortedByDirections"
            :class="`cursor-context-menu scroller tbody ${group.isPartOfGroup ? 'is-part-of-group' : ''}`"
            @page-count="syncPageCount = $event"
          >
            <template v-slot:header="{ props: { headers } }">
              <thead
                style="position: sticky; top: 0px;"
                class="white"
                @contextmenu="toggleColumnMenu($event)"
                @dblclick="toggleColumnMenu($event)"
              >
                <tr>
                  <th
                    v-for="(header, headerIndex) in headers"
                    :key="headerIndex"
                    :class="`coolgray--text font-weight-semibold ${header.classes}`"
                    :style="header.styles"
                    :title="header.tooltip || header.text"
                  >
                    <v-row>
                      <v-col
                        :class="`col-10 d-flex align-center justify-${header.align} py-0`"
                        :style="`width: ${header.widthPx};`"
                      >
                        <div
                          :class="`text-truncate ${header.sortClassText}`"
                        >
                          {{ header.text }}
                        </div>
                        <v-chip
                          :class="`cursor-pointer white ${header.sortClassIndicator}`"
                          label
                          outlined
                          overlap
                          top
                          x-small
                          @click="updateSorting(header)"
                        >
                          <v-row>
                            <v-icon
                              small
                            >
                              {{ header.sortIcon }}
                            </v-icon>
                            <div class="text-smaller">
                              {{ (header.sortIndex !== -1 && header.sortIndex) || '–' }}
                            </div>
                          </v-row>
                        </v-chip>
                      </v-col>

                      <column-resizer
                        :column-index="headerIndex"
                        :column-width="header.width"
                      />
                    </v-row>
                  </th>
                </tr>
              </thead>
            </template>

            <template v-slot:item="{ headers, item, index }">
              <tr
                :class="(contextMenuItemIndex && contextMenuItemIndex === index && 'coolbeige') || 'white'"
                @contextmenu="toggleContextMenu($event, item, index)"
                @dblclick="toggleContextMenu($event, item, index)"
                @click="resetContextMenu(null)"
              >
                <td
                  v-for="(header, hIndex) in headers"
                  :key="hIndex"
                  :class="header.classes"
                  :style="header.styles"
                >
                  <v-row
                    :style="`width: ${header.widthPx};`"
                  >
                    <table-column
                      :header="header"
                      :item="item"
                    />
                  </v-row>
                </td>
              </tr>
            </template>
          </v-data-table>

          <v-menu
            v-model="contextMenu"
            absolute
            offset-y
            :position-x="contextMenuX"
            :position-y="contextMenuY"
            transition="scale-transition"
            width="300px"
          >
            <v-card>
              <v-card-actions>
                <v-list dense>
                  <v-list-item
                    v-for="(action, actionIndex) in actions"
                    :key="actionIndex"
                    @click="fireActionEvent(action, contextMenuItem)"
                  >
                    <v-list-item-content class="body-2">
                      {{ action.name }}
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card-actions>
            </v-card>
          </v-menu>

          <v-menu
            v-model="columnMenu"
            absolute
            max-height="300"
            min-width="300"
            offset-y
            :close-on-content-click="false"
            :position-x="columnMenuX"
            :position-y="columnMenuY"
            bottom
            transition="scale-transition"
            width="300px"
          >
            <column-picker
              :current-required-columns="currentRequiredColumns"
              :report-key="reportKey"
              :table-columns="tableColumns"
            />
          </v-menu>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'underscore'
import { createNamespacedHelpers } from 'vuex'

import { fireanalytics } from 'src/store/firebase'

import dates from 'src/utils/dates'
import format from 'src/utils/format'
import sort from 'src/utils/sort'

import ColumnPicker from './ColumnPicker'
import ColumnResizer from './ColumnResizer'
import TableColumn from './TableColumn'

const { mapActions, mapGetters, mapState } = createNamespacedHelpers('tools')

const { direction: SortDirection, label: SortLabel } = sort

export default {
  name: 'DataTable',
  components: {
    // Dynamic import caused a split-second lag.
    ColumnPicker,
    ColumnResizer,
    TableColumn,
  },
  props: {
    actions: {
      type: Array,
      default: () => [],
    },
    dataGroups: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      columnMenu: false,
      columnMenuX: 0,
      columnMenuY: 0,
      contextMenu: false,
      contextMenuItem: null,
      contextMenuItemIndex: null,
      contextMenuX: 0,
      contextMenuY: 0,
      format,
      observer: null,
      SortDirection,
      tableOptions: {},
    }
  },
  computed: {
    ...mapGetters([
      'columnsByKey',
      'currentColumns',
      'currentItems',
      'currentGroupByProperties',
      'currentGroups',
      'currentlySorting',
      'currentNumberOfAppliedFilters',
      'currentSorted',
      'currentSorting',
      'currentSummary',
      'currentViewId',
      'frozenColumns',
      'isProgrammaticRoute',
      'keyField',
      'reportColumns',
      'reportKey',
      'showCurrentSavedFilters',
      'sizeField',
      'sortByDirections',
      'sortByKeys',
      'storedItems',
      'tableColumns',
      'visibleColumns',
    ]),
    ...mapState({
      applyingFilter: (state) => state.applyingFilter,
      displaySavedFilters: (state) => state.displaySavedFilters,
      dragging: (state) => state.dragging,
      hasClickedToggler: (state) => state.hasClickedToggler,
      isTogglingTables: (state) => state.isTogglingTables,
      itemsPerPage: (state) => state.itemsPerPage,
      itemsPerPageOptions: (state) => state.itemsPerPageOptions,
      page: (state) => state.page,
      pageCount: (state) => state.pageCount,
      reportId: (state) => state.reportId,
      reportName: (state) => state.reportName,
      resettingFilter: (state) => state.resettingFilter,
      showRows: (state) => state.showRows,
      subId: (state) => state.subId,

      requiredColumns: (state) => state.requiredColumns,
      sortClassIndicatorMap: (state) => state.sortClassIndicatorMap,
      sortClassTextMap: (state) => state.sortClassTextMap,
      sortIcons: (state) => state.sortIcons,
      sortingByKeys: (state) => state.sortingByKeys,
      sortingByDirections: (state) => state.sortingByDirections,
      tableSortMap: (state) => state.tableSortMap,
    }),

    syncPage: {
      get() {
        return this.page
      },
      set(value) {
        this.$store.commit('tools/page', value)
      },
    },
    syncPageCount: {
      get() {
        return this.pageCount
      },
      set(value) {
        this.$store.commit('tools/pageCount', value)
      },
    },
    syncItemsPerPage: {
      get() {
        return this.itemsPerPage
      },
      set(value) {
        this.$store.commit('tools/itemsPerPage', value)
      },
    },

    sortedByDirections: {
      get() {
        return this.sortByDirections
      },
      set(newValue) {
        this.$store.commit('tools/sortingByDirections', newValue)
      },
    },
    sortedByKeys: {
      get() {
        return this.sortByKeys
      },
      set(newValue) {
        this.$store.commit('tools/sortingByKeys', newValue)
      },
    },

    currentRequiredColumns() {
      return this.requiredColumns[this.reportKey] || []
    },
    addPaddingToTable() {
      const { displaySavedFilters, reportKey } = this
      const isPacingReport = reportKey === 'pacing'

      return !isPacingReport || displaySavedFilters[reportKey]
    },
    isReady() {
      return !!this.currentViewId
    },
    isReadyAndHasResults() {
      const { currentGroups, isReady } = this
      return isReady && !!currentGroups?.length
    },
    isReadyAndNoData() {
      const { currentGroups, isReady } = this

      return isReady && !currentGroups?.length
    },
    allGroupsAreEmpty() {
      const { currentGroups } = this
      const foundItems = currentGroups.find((x) => x.items.length > 0)

      return !foundItems
    },
    tableGroups() {
      return this.dataGroups || this.currentGroups
    },
    tableFooterProps() {
      return {
        absolute: true,
        'items-per-page-options': this.itemsPerPageOptions,
      }
    },
    topPadding() {
      return this.currentGroupByProperties.length ? 'mt-1' : 'mt-0'
    },
  },
  methods: {
    ...mapActions(['keepDragging', 'startDragging', 'stopDragging', 'toggleRows']),
    toggleColumnMenu(event) {
      event.preventDefault()

      const newValue = !this.columnMenu

      if (newValue) {
        this.columnMenuX = event.clientX
        this.columnMenuY = event.clientY
        this.columnMenu = newValue
      } else {
        this.columnMenu = newValue
      }
    },
    resetContextMenu(newValue) {
      this.contextMenu = newValue
      this.contextMenuItemIndex = null
      this.contextMenuItem = null
    },
    toggleContextMenu(event, item, itemIndex) {
      event.preventDefault()

      const newValue = !this.contextMenu

      if (newValue) {
        this.contextMenuItem = item
        this.contextMenuItemIndex = itemIndex
        this.contextMenuX = event.clientX
        this.contextMenuY = event.clientY
        this.contextMenu = newValue
      } else {
        this.resetContextMenu(newValue)
      }
    },
    fireActionEvent(action, item) {
      this.$store.dispatch(action.event, item)
    },
    haveDataButNoResultsInGroup(group) {
      const { allGroupsAreEmpty, isReadyAndHasResults } = this

      if (allGroupsAreEmpty) return false

      return isReadyAndHasResults && group.items.length === 0
    },
    getClassNames(value, column) {
      const { formatAs = 'text' } = column
      const isNonTextType = ['boolean', 'number', 'percentage'].indexOf(formatAs) !== -1
      const formatClass = `column-${isNonTextType ? 'narrow' : formatAs}`
      const xAxisPadding = 'pl-0 pr-3'
      // const isFirstColumn = columnIndex === 0
      // const xAxisPadding = isFirstColumn ? 'px-3' : 'pl-0 pr-3'

      return `${xAxisPadding} ${formatClass} ${column.classes}`
    },
    formatNumber(value) {
      return format.number(value)
    },
    sortItems({ columnKey, columnDirection, columnFormat, items }) {
      const sortDsc = columnDirection === this.tableSortMap.DSC
      const sorter = sortDsc ? _.sortDscByCaseInsensitive : _.sortAscByCaseInsensitive
      const isDateColumn = columnFormat === 'date'

      const valueGetter = (item) => {
        const getValue = () => {
          const value = !isDateColumn && item.normalized ? item.normalized[columnKey] : item[columnKey]
          const sortArrayValues = () => (sortDsc ? value.sort().reverse() : value.sort())
          return Array.isArray(value) ? sortArrayValues()[0] : value?.value || value
        }
        const getDate = (value) => dates.getUnixDate(value)
        return isDateColumn ? getDate(getValue()) : getValue()
      }

      return sorter(items, valueGetter)
    },
    addSortingOption(optionIndex, header) {
      const { ASC } = this.tableSortMap
      const addOption = () => {
        this.sortedByKeys.splice(this.sortedByKeys.length, 0, header.key)
        this.sortedByDirections.splice(this.sortedByDirections.length, 0, ASC)

        header.sortClassIndicator = `brightblue--text ${this.sortClassIndicatorMap[header.align]}`
        header.sortDsc = ASC
        header.sortIcon = this.sortIcons.ASC
        header.sortIndex = this.sortedByKeys.length

        fireanalytics.event('goal_completion', {
          action: 'click',
          category: 'Sort',
          description: 'Add Sort Option',
          label: `Add Sort Option "${header.key}" with Direction "${SortLabel[header.sortDsc]}" at Index ${header.sortIndex}`,
        })

        return true
      }

      return (optionIndex === -1 && addOption()) || false
    },
    deleteSortingOption(optionIndex, header) {
      const { ASC, DSC } = this.tableSortMap
      const removeOption = () => {
        fireanalytics.event('goal_completion', {
          action: 'click',
          category: 'Sort',
          description: 'Remove Sort Option',
          label: `Remove Sort Option "${header.key}" with Direction "${SortLabel[header.sortDsc]}" at Index ${header.sortIndex}`,
        })

        this.sortedByDirections.splice(optionIndex, 1)
        this.sortedByKeys.splice(optionIndex, 1)

        this.sortedByKeys.forEach((key, sortIndex) => {
          const foundHeader = this.columnsByKey[key]
          foundHeader.sortIndex = sortIndex + 1
        })

        // @TODO Avoid issue w/ sortClassIndicator's text color pre- vs post-click by abstracting the values.
        header.sortClassIndicator = `midgray--text ${this.sortClassIndicatorMap[header.align]}`
        header.sortDsc = ASC
        header.sortIcon = this.sortIcons.ASC
        header.sortIndex = -1

        return true
      }
      return (this.sortedByDirections[optionIndex] === DSC && removeOption()) || false
    },
    updateSortingOption(optionIndex, header) {
      const { ASC, DSC } = this.tableSortMap
      const currentDirection = this.sortedByDirections[optionIndex]
      const newDirection = currentDirection === DSC ? ASC : DSC
      const newSortIndex = optionIndex + 1

      fireanalytics.event('goal_completion', {
        action: 'click',
        category: 'Sort',
        description: 'Update Sort Option',
        label: `Update Sort Option "${header.columnName}" (${header.key}) from Direction "${SortLabel[header.sortDsc]}" to Direction "${newDirection}" at Index ${header.sortIndex}`,
      })

      header.sortClassIndicator = `brightblue--text ${this.sortClassIndicatorMap[header.align]}`
      header.sortDsc = newDirection
      header.sortIcon = this.sortIcons[newDirection]
      header.sortIndex = newSortIndex

      this.sortedByDirections.splice(optionIndex, 1, newDirection)

      return true
    },
    updateSorting(header) {
      const optionIndex = this.sortedByKeys.findIndex((key) => key === header.key)

      return this.addSortingOption(optionIndex, header)
          || this.deleteSortingOption(optionIndex, header)
          || this.updateSortingOption(optionIndex, header)
    },
    setTableSorting(unsortedItems) {
      const { columnsByKey } = this
      const sortItems = (items, columnKey, index) => this.sortItems({
        columnDirection: this.sortedByDirections[index],
        columnFormat: columnsByKey[columnKey]?.formatAs,
        columnKey,
        items,
      })

      return this.sortedByKeys.reduceRight(sortItems, unsortedItems)
    },
  },
}
</script>

<style lang="scss" scoped>
@import "src/styles/tables";

#header-thead {
  position: sticky;
  top: 0;
}

#header-tbody {
  position: absolute;
  top: 0;

  overflow: scroll;
  width: max-content;

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
#header-tbody::-webkit-scrollbar {
  display: none;
}
</style>
