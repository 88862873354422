<template>
  <v-col
    :class="classesForColumnResizer"
    :data-columnIndex="columnIndex"
    @mousedown="startDragging({ event: $event, columnIndex })"
  >
    <v-tooltip bottom>
      <template v-slot:activator="{ on: tooltipOn, attrs: tooltipAttrs }">
        <v-btn
          color="coolgray"
          class="cursor-resizer"
          dark
          icon
          small
        >
          <v-icon
            color="midgray"
            x-small
            style="transform: rotate(90deg)"
            v-bind="tooltipAttrs"
            v-on="tooltipOn"
          >
            compress
          </v-icon>
        </v-btn>
      </template>

      <div>{{ columnWidth }} pixel width</div>
    </v-tooltip>
  </v-col>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

const { mapActions, mapState } = createNamespacedHelpers('tools')

export default {
  name: 'ColumnResizer',
  props: {
    columnIndex: {
      type: Number,
      default: -1,
    },
    columnWidth: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    ...mapState({
      dragging: (state) => state.dragging,
    }),
    classesForColumnResizer() {
      const draggingClass = this.dragging && this.dragging.index === this.columnIndex && ' column-dragging'
      return `col-2 pa-0 text-right column-resizer${draggingClass || ''}`
    },
  },
  methods: {
    ...mapActions(['startDragging']),
  },
}
</script>

<style lang="scss" scoped>
@import "src/styles/tables";
</style>
