<template>
  <v-card
    class="white"
  >
    <v-card-title class="py-1">
      <v-row>
        <v-col>
          <span class="subtitle-2">
            Column Controls
          </span>

          <v-icon
            small
            :class="`ml-1 ${showInfo ? 'primary' : 'midgray'}--text`"
            title="Instructions"
            @click="toggleInfo"
          >
            help
          </v-icon>
        </v-col>
      </v-row>
    </v-card-title>

    <v-divider class="mb-0" />

    <v-card-text class="pt-0 pl-0">
      <v-row
        v-if="showInfo"
        class="mt-0 mx-0"
      >
        <v-col>
          <p
            class="mb-0 caption"
          >
            <v-icon
              small
              class="mx-2"
            >
              drag_handle
            </v-icon>
            <span>Drag columns below to resort their order.</span>
          </p>
          <p class="mb-0 caption">
            <v-icon
              small
              class="mx-2"
            >
              push_pin
            </v-icon>
            <span>Pin columns to freeze them in place.</span>
          </p>
          <p class="mb-0 caption">
            <v-icon
              small
              class="mx-2"
            >
              check_box
            </v-icon>
            <span>Toggle checkboxes to show &amp; hide columns.</span>
          </p>
        </v-col>
      </v-row>

      <v-row class="ma-0">
        <v-list
          bottom
          class="white"
        >
          <draggable
            v-model="orderedColumns"
          >
            <v-list-item
              v-for="(column, colIndex) of orderedColumns"
              :key="colIndex"
              class="white"
              dense
            >
              <v-list-item-icon>
                <v-icon
                  color="midgray"
                  class="mr-3 cursor-grab"
                  :title="`Drag ${column.columnName}`"
                >
                  drag_handle
                </v-icon>

                <v-icon
                  :color="`${column.frozen ? 'offblue' : 'midgray'}`"
                  class="mr-3"
                  :title="`${column.frozen ? 'Unfreeze' : 'Freeze'} ${column.columnName}`"
                  @click="freezeColumn(column.columnName)"
                >
                  push_pin
                </v-icon>

                <v-icon
                  :color="`${column.visible && !column.required ? 'offblue' : 'midgray'}`"
                  :disabled="currentRequiredColumns.indexOf(column.key) !== -1"
                  :title="`${column.visible ? 'Hide' : 'Show'} ${column.columnName}`"
                  @click="toggleColumn(column.columnName)"
                >
                  {{ column.visible ? 'check_box' : 'check_box_outline_blank' }}
                </v-icon>
              </v-list-item-icon>

              <v-list-item-title
                :class="`ml-6 font-weight-medium${column.required ? ' align-self-end mt-3' : ''}`"
              >
                <span>
                  {{ column.columnName }}
                </span>

                <v-list-item-subtitle
                  v-if="column.tooltip"
                  class="midgray--text text-small caption"
                >
                  {{ column.tooltip }}
                </v-list-item-subtitle>

                <v-list-item-subtitle
                  v-if="column.required"
                  class="midgray--text text-small caption"
                >
                  Required
                </v-list-item-subtitle>
              </v-list-item-title>
            </v-list-item>
          </draggable>
        </v-list>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import draggable from 'vuedraggable'

const { mapGetters: mapToolsGetters } = createNamespacedHelpers('tools')

export default {
  name: 'ColumnPicker',
  components: { draggable },
  props: {
    currentRequiredColumns: {
      type: Array,
      default: () => [],
    },
    reportKey: {
      type: String,
      default: undefined,
    },
    tableColumns: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      showInfo: false,
    }
  },
  computed: {
    ...mapToolsGetters(['frozenColumns']),
    orderedColumns: {
      get() {
        return this.tableColumns
      },
      set(value) {
        this.$store.commit('tools/columns', { [this.reportKey]: value })
      },
    },
  },
  methods: {
    toggleInfo() {
      this.showInfo = !this.showInfo
    },
    freezeColumn(columnName) {
      const { frozenColumns, orderedColumns, reportKey } = this
      const findColumnByName = (col) => col.columnName === columnName
      const columnIndex = orderedColumns.findIndex(findColumnByName)
      const newColumns = [...orderedColumns]
      const column = newColumns[columnIndex]
      const newFrozenValue = !column.frozen

      newColumns[columnIndex].frozen = newFrozenValue

      if (newFrozenValue) {
        const seedPadding = 0

        newColumns[columnIndex].left = frozenColumns.reduce((leftPixels, leftSibling) => {
          leftPixels += leftSibling.width
          return leftPixels
        }, seedPadding)
      }

      this.$store.commit('tools/columns', { [reportKey]: newColumns })

      return newColumns
    },
    toggleColumn(columnName) {
      const { currentRequiredColumns, tableColumns, reportKey } = this
      const findColumnByName = (col) => col.columnName === columnName
      const columnIndex = tableColumns.findIndex(findColumnByName)
      const newColumns = [...tableColumns]
      const column = newColumns[columnIndex]
      const isRequiredColumn = currentRequiredColumns?.length && currentRequiredColumns.indexOf(column.key) !== -1

      if (isRequiredColumn) {
        const message = 'Cannot Hide Required Column'

        this.$store.commit('tools/snackbar', true)
        this.$store.commit('tools/snack', { message, status: 'error' })

        return false
      }

      newColumns[columnIndex].visible = !column.visible

      this.$store.commit('tools/columns', { [reportKey]: newColumns })

      return newColumns
    },
  },
}
</script>
