<template>
  <v-col>
    <data-table
      ref="table"
      :actions="actions"
    />
  </v-col>
</template>

<script>
import DataTable from 'src/tools/components/table/DataTable'

export default {
  name: 'Orders',
  components: { DataTable },
  data() {
    return {
      actions: [
        {
          event: 'admanager/openLineItems',
          name: 'Open Line Items',
        },
        {
          event: 'admanager/openCreatives',
          name: 'Open Creatives',
        },
      ],
    }
  },
}
</script>
